import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor( private translate: TranslateService ) {
    this.setAppLanguage();
  }

  setAppLanguage(){
    this.translate.getBrowserLang();
    this.translate.setDefaultLang('es');
    this.translate.use('es');
    const supportedLangs = ["en", "es"];
    supportedLangs.forEach((language) => {
      this.translate.reloadLang(language);
    });
  }

  initializeApp() {}
}
